class ZIDXAccountHelpSetupWizard implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountHelpSetupWizardContainer";
    }
    getMatchURL(){
        return "/account/help/setup-wizard";
    }
    render(){
        // console.log("setup wizard");
    }
}